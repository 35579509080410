<template>
  <div class="contantTop flex flex_center flex_column">
    <div class='middle'>
      <div class='title'>首 望</div>
      <div class='remakes'>
        欢迎使用首望后台系统！我们始终为客户提供好的产品和技术支持、健全的售后服务
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Home',
  data() {
    return {
      
    }
  },
  mounted() {
    
  },
  methods: {
    
  },
}
</script>
<style lang="less" scoped>
  .contantTop{
    width: 100%;
    height: 90%;
    padding:0 !important;
    margin: 0 !important;
    background-image: url('../../assets/img/bgHome.png');
    background-size:100%;
    background-position: center;
  }
  .homeTitle{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 30px;
    font-weight: bold;
  }
  .middle{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
    padding-left:115px;
    box-sizing: border-box;
  }
  .middle>.title{
    width: 447px;
    height: 160px;
    opacity: 1;
    font-size: 49px;
    font-family: STSongti, STSongti-Black;
    font-weight: 900;
    text-align: left;
    color: #3f445b;
    line-height: 80px;
  }
  .middle>.remakes{
    width: 562px;
    height: 52px;
    opacity: 1;
    font-size: 19px;
    font-family: PingFangSC, PingFangSC-Regular;
    font-weight: 400;
    text-align: left;
    color: #3f445b;
    line-height: 26px;
    margin:31px 0 46px;
  }
</style>
